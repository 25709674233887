(function($) {
  Drupal.behaviors.mobileGnav = {

    init: function() {
      var nav = $('.navigation');
      var navContainer = $('.navigation_container');
      var navControl = $('#navigation_control');
      var bodyOffsetTop = $('body').offset().top;
      var mainContainer = $('.main_container');
      var btnNav = $('.btn_navigation');
      var mainContainerHeight = $('.main_container').height();
      var animationDuration = { duration: 250, queue: false };

      btnNav.on('click', function() {
        navContainer.animate({
          width: '65%'
        }, animationDuration);
        mainContainer.animate({
          width: '35%'
        }, animationDuration);
        navContainer.find('ul.menu li').css({'opacity': '0'});
        setTimeout(function() {
          navContainer.find('ul.menu li').css({'opacity': '1'});
        }, 100);
        mainContainer.css({'height': 'auto'});
        navContainer.css({'height': +mainContainerHeight + 'px'});
        navControl.show();
      });

      navControl.on('click', function() {
        $(this).hide();
        navContainer.animate({
          width: '0'
        }, animationDuration);
        mainContainer.animate({
          width: '100%'
        }, animationDuration);
        mainContainer.css({'max-height': '100%'});
      });

      // TouchWipe implementation. Open nav on swipe right.
      btnNav.touchwipe({
        wipeRight: function() {
          navContainer.animate({
            width: '65%'
          }, animationDuration);
          mainContainer.animate({
            width: '35%'
          }, animationDuration);
        },
        min_move_x: 20,
        min_move_y: 20,
        preventDefaultEvents: true
      });

      // TouchWipe implementation. Close nav on swipe left.
      navControl.touchwipe({
        wipeLeft: function() {
          //alert('swipe left screen.width: ' + screen.width);
          $(this).hide();
          navContainer.animate({
            width: '0'
          }, animationDuration);
          mainContainer.animate({
            width: '100%'
          }, animationDuration);
        },
        min_move_x: 20,
        min_move_y: 20,
        preventDefaultEvents: true
      });

      // Loop through the navigation object
      nav.each(function() {
        var thisCats = $(this);

        // Every first li item should be this color #D9D9D9
        thisCats.find('.depth-1 > ul.menu > li.first').each(function() {
          $(this).css({'background-color': '#D9D9D9'});
        });

        thisCats.find('.depth-2 > ul.menu > li.first').each(function() {
          $(this).css({'background-color': '#D9D9D9'});
        });

        // Main categories
        thisCats.find('.field-mobile-menu > ul.menu > li > a').each(function() {
          var menuItemElement = $(this);
          var menuItemText = cleanText(menuItemElement.find('span:first').text());
          menuItemElement.parent().addClass('parent parent-' + menuItemText);
          menuItemElement.next().addClass(menuItemText);
          if (menuItemElement.attr('href').length > 1) {
            // Hide ">" icon on menu items without children
            menuItemElement.find('.icon').hide();
          }
          menuItemElement.on('click', function(e) {
            var menuItemSubElement = $(this);
            // Append "?_SUBMIT=signout" to the logout URL
            var logout = menuItemSubElement.siblings('.menu-container.depth-1.mi-cuenta-').find('ul.menu').find('li.parent-salir').find('a').attr('href', '/account/signin.tmpl?_SUBMIT=signout');
            // This is to replace the "Back" text string with the clicked menu item text string
            menuItemSubElement.parent().find('li.parent-back > menuItemSubElement').html(menuItemSubElement.text() + '<span class="icon"></span>');
            var thisChildrenClass = menuItemSubElement.children('span:first').attr('class');
            if (thisChildrenClass !== 'icon') {
              if (menuItemSubElement.next().hasClass(menuItemText)) {
                menuItemSubElement.parent().siblings().each(function() {
                  // Show chosen cat submenus
                  $('.menu-container.depth-1.' + menuItemText).show();
                  // Hide all that doesn't match chosen cat class
                  $('.menu-container.depth-1').not('.' + menuItemText).hide();
                });
              }
            }
            // Only go back if href have '/' (<front>) URL. If href have any other ('/page.tmpl') URL proceed to that page
            if (menuItemSubElement.attr('href').length === 1) {
              e.preventDefault();
              window.scrollTo(-100, 0); // first value for left offset, second value for top offset
              nav.animate({
                left: '-100%',
                scrollTop: bodyOffsetTop
              }, { duration: 200 });
            }
          });
        });

        // Sub categories
        thisCats.find('.depth-1 > ul.menu > li > a').each(function() {
          var depthItemElement = $(this);
          var depthOneMenuItemText = cleanText(depthItemElement.text());

          depthItemElement.parent().addClass('parent parent-' + depthOneMenuItemText).removeClass('parent parent-');
          depthItemElement.next().addClass(depthOneMenuItemText);
          if (depthItemElement.attr('href').length > 1) {
            // Hide ">" icon on menu items without children
            depthItemElement.find('.icon').hide();
          }
          depthItemElement.on('click', function(e) {
            depthItemElement.parent().find('li.parent-back > a').html(depthItemElement.text() + '<span class="icon"></span>');
            depthItemElement.parent().addClass('parent-' + depthOneMenuItemText);
            if (depthItemElement.next().hasClass(depthOneMenuItemText) && depthItemElement.next().attr('class') !== 'undefined') {
              // Show chosen cat submenus
              $('.menu-container.depth-2.' + depthOneMenuItemText).show();
              // Hide all that doesn't match chosen cat class
              $('.menu-container.depth-2').not('.' + depthOneMenuItemText).hide();
            }
            // Only go back if href have '/' URL. If href have any '/page.tmpl' URL proceed to that page
            if (depthItemElement.attr('href').length === 1) {
              e.preventDefault();
              // Temp workaround for the "My Account" menu item. We don't have URLs for it yet...
              if (depthItemElement.parent().parent().parent().hasClass('my-account') && depthItemElement.parent().index() > 0) {
                nav.animate({
                  left: '0',
                  scrollTop: bodyOffsetTop
                }, { duration: 200 });
              } else if (depthItemElement.parent().index() !== 0) {
                nav.animate({
                  left: '-200%',
                  scrollTop: bodyOffsetTop
                }, { duration: 200 });
              } else {
                nav.animate({
                  left: '0',
                  scrollTop: bodyOffsetTop
                }, { duration: 200 });
              }
            }
          });
        });

        // Sub sub categories
        thisCats.find('.depth-2 > ul.menu > li > a').each(function() {
          var depthItemElement = $(this);
          var depthOneMenuItemText = cleanText(depthItemElement.text());
          depthItemElement.parent().addClass('parent parent-' + depthOneMenuItemText).removeClass('parent parent-');
          depthItemElement.next().addClass(depthOneMenuItemText);
          if (depthItemElement.attr('href').length > 1) {
            // Hide ">" icon on menu items without children
            depthItemElement.find('.icon').hide();
          }
          depthItemElement.on('click', function(e) {
            // Only go back if href have '/' (<front>) URL. If href have any other ('/page.tmpl') URL proceed to that page
            if (depthItemElement.attr('href').length === 1) {
              e.preventDefault();
              // Back to previous cats if this is '/' or doesn't have any children cat
              if (depthItemElement.parent().index() >= 0) {
                nav.animate({
                  left: '-100%',
                  scrollTop: bodyOffsetTop
                }, { duration: 200 });
              }
            }
          });
        });

        // Append default user picture to the my account category
        thisCats.find('.parent-my-account > a').prepend('<span class="user-pic"></span>');

        // Append checkout icon to the "Checkout" category
        thisCats.find('.parent-checkout a').prepend('<span class="checkout-icon gnav"></span>');
      });

      // Append welcome message to the logged-in user
      $('.logged-in .my-account ul li.first').after('<li class="menu-mlid-051660ec-3c0d-11e3-814d-000c29cc69ea parent parent-welcome"><span class="welcome-user"><span class="icon"><span class="user-pic"></span></span><span class="welcome"></span><span class="welcome-name"></span></span></li>');

      // Footer menu
      $('#footer').find('.footer-bottom ul.menu li').each(function(i) {
        var item = $(this);
        item.attr('data-item', i).addClass('item-' + i);
      });

      // Remove unneeded characters to create CSS classes from anchor text.
      function cleanText(txt) {
        // TODO: optimize with regex...
        txt = txt.replace('\'', '');
        txt = txt.replace(' & ', '-');
        txt = txt.replace('/', '-');
        txt = txt.replace('---', '-');
        txt = txt.replace(/\s/g, '-');
        txt = txt.replace('3', 'three');
        txt = txt.toLowerCase();
        return txt;
      }
    },

    attach: function() {
      this.init();
    }
  };
})(jQuery);
